// intersectionObserverMixin.js
export default {
  methods: {
    setupIntersectionObserver(selector, threshold = 0.2) {
      const boxes = document.querySelectorAll(selector);
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show");
              this.observer.unobserve(entry.target);
            }
          });
        },
        { threshold }
      );
      boxes.forEach((box) => this.observer.observe(box));
    },
  },
  beforeDestroy() {
    // Disconnect observer to prevent memory leaks
    if (this.observer) this.observer.disconnect();
  },
};
