import { render, staticRenderFns } from "./PrivacyTermsPage.vue?vue&type=template&id=7e68fb29&"
import script from "./PrivacyTermsPage.vue?vue&type=script&lang=js&"
export * from "./PrivacyTermsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports